<template>
  <v-container class="max-container">
    <v-card
      class="mx-auto card-box"
      tile
      dark
      color="black"
    >
      <v-card-text>
        <div class="layout column align-center">
          <!-- <div v-show="!isWebView" class="v-card__title justify-center display-1 mb-2">
            <v-img :src="logo" max-height="120" max-width="120" />
          </div> -->
          <div class="v-card__subtitle justify-center display-2 mb-1" style="text-align:center;color:white;margin-top:1em">
            Cancel Subscription
          </div>
        </div>
      </v-card-text>
      <div class="button-box">
        <v-btn v-if="survey_id" block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="loading" @click="cancelSub">
          Cancel Subscription
        </v-btn>
        <v-btn v-else block depressed class="tile text-none commit-subscription" color="cyan accent-4" @click="startSurvey">Continue</v-btn>
      </div>
    </v-card>
    <over-dialog ref="overdialog" :ok-handle="goTo" />
  </v-container>
</template>

<script>
import OverDialog from './components/overDialog'
import {
  cancelSub } from '@/api/user'
export default {
  name: 'CancelSub',
  components: {
    OverDialog
  },
  data() {
    return {
      survey_id: '',
      loading: false
    }
  },
  created() {
    if (this.$route.query && this.$route.query.survey_id && this.$route.query.survey_id === '27324846') {
      window.Intercom('startSurvey', 27324846)
      setTimeout(() => {
        this.survey_id = this.$route.query.survey_id
      }, 2 * 1000)
    }
  },
  methods: {
    startSurvey() {
      window.Intercom('startSurvey', 27324846)
      setTimeout(() => {
        this.survey_id = 27324846
      }, 2 * 1000)
    },
    cancelSub() {
      if (!this.survey_id || this.loading) {
        return
      }
      this.loading = true
      cancelSub().then(async res => {
        if (res.code === 0) {
          await this.$store.dispatch('user/getInfo')
          this.$refs.overdialog.controlDialog(true, res.message, 'Cancel success', false, false)
        } else {
          this.showNackBar('error', res.message)
        }
      }, () => {}).finally(() => {
        this.loading = false
      })
    },
    goTo() {
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      this.$router.push({ name: 'mychannel' })
    }
  }
}
</script>

<style lang="scss" scoped>
.button-box{
  margin-top:100px;
  .v-btn{
    margin-top: 12px;
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
    font-size: 14px;
    min-width: 0;
    .v-btn__content{
      padding: 0;
    }
  }
}
</style>
